/* styles.css */

.swal2-popup .swal2-confirm {
  background-color: #3085d6;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.swal2-popup .swal2-confirm:hover {
  background-color: #2672b8;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.video-container {
  position: relative;
  width: 100%;
  height: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
 }

.video-overlay {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  height: 30vh;
  display: none;
  transition: opacity 0.3s;
  color: white;
  font-size: 24px;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.video-container video.paused ~ .video-overlay {
  display: block;
}

.video-container video {
  width: 100%;
  height: auto;
  outline: none;
  border-radius: 10px;
}

.video-overlay:hover {
  opacity: 0.7;
}

.button-overlay {
  position: absolute;
  bottom: 10%;
  left: 36%;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  font-size: 1.5rem;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.carousel-btn {
  background-color: #3085d6;
  border: none;
  border-radius: 50%;
  color: white;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.carousel-btn:hover {
  background-color: #2672b8;
}

.carousel-btn:active {
  transform: scale(0.95);
}

.swal2-title {
  font-size: 2rem;
  font-weight: bold;
  color: #000000;
  margin-bottom: 20px;
}

.button-overlayy {
  margin-top: 2%;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  font-size: 1.5rem;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.button-overlayy:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.button-overlayy:active {
  transform: translateY(-50%) scale(0.95);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .swal2-popup {
    width: 100% !important;
    padding: 2em !important;
  }
  .swal2-title {
    font-size: 1.5rem;
  }
  .video-container {
    width: 100%;
   }
 
  .button-overlayy {
    font-size: 1rem;
    padding: 8px 16px;
  }
}

.carousel-indicators {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
}

.carousel-indicator {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: none;
  background-color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
}

.carousel-indicator.active {
  background-color: white;
}

.carousel-play-pause {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
}
