.certificado-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  padding-top: 22%;
  /* Ajustamos el tamaño de la fuente y el padding para pantallas pequeñas */
  font-size: 1.2rem;

  @media (min-width: 768px) {
    padding: 30rem;
    font-size: 1.5rem;
    
   }

  @media (min-width: 1024px) {
    padding: 4rem;
    font-size: 2rem;
  }
}

.certificado-titulo {
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }

  @media (min-width: 1024px) {
    font-size: 3rem;
  }
}

.certificado-imagen {
  max-width: 65%; /* Permite que la imagen ocupe todo el ancho disponible */
  height: auto;
  border: 1px solid #ddd;
  border-radius: 5px;

  @media (max-width: 767px) {
    transform: scale(1.5); /* Scale up on mobile */
    margin: 10%;
  }

  transition: transform 0.3s ease-in-out; /* Agrega la transición */

}

/* Estilos para el certificado expandido */
.certificado-container.expanded {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  transform: scale(1.5); /* Aumenta el tamaño del contenedor */
  h2 {
    display: none;
  }

}


  .expanded .certificado-imagen {
  max-width: 90vw;
  height: auto;
  transform: rotate(90deg);


 
}

/* Botón de cerrar */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
}
 