 

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.fondo{
  background: url('./img/fondoC.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}
/* Fuente global */
body {
  overflow-x: hidden;
  font-family: 'Montserrat'; /* Utiliza Montserrat-Bold como fuente global */
}

/* Fuentes personalizadas */
@font-face {
  font-family: 'Montserrat-Bold';
  src:url('./tipografia/Montserrat-Bold.ttf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Nikaia';
  src: url('./tipografia/Nikaia.otf') ;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Escobar';
  src:url('./tipografia/Escobar-BF64b81952df573.otf');
  font-weight: normal;
  font-style: normal;
}

/* Estilos para h1 */
h1 {
  font-family: 'Nikaia'; /* Utiliza Nikaia para h1 */
}
 

/* Estilos para h2 */
h2 {
  font-family: 'Escobar'; /* Utiliza Escobar para h2 */
}
h3 {
  font-family: 'Escobar'; /* Utiliza Escobar para h2 */
}
 

@keyframes playOnce {
  to {
    background-position: 100% 100%;
  }
}

.fondoBanner {
  background-image: url('./videos/fondoBanner.gif');
  background-repeat: no-repeat;
  background-size: cover;
  animation: playOnce 1s forwards; /* reproduce el gif una vez en 1 segundo */
}



span{
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #fff;
  
}
.button-hover-effect {
  position: relative;
  overflow: hidden;
}

.button-hover-effect::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: -100%;
  background: #203ce0  ; /* Ajusta el color según tu diseño */
  transition: left 0.4s ease;
}

.button-hover-effect:hover::after {
  left: 0;
}

.levitate-animation {
  animation: levitate 1.5s ease-in-out infinite;
}


@keyframes levitate {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

/* En tu archivo CSS (por ejemplo, styles.css) */
.shadow-intense {
  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.5);
}


.linea{
  width: 100%;
background-color: black;
height: .50vh;
margin-bottom: 4%;
}

.linea-dos{
  width: 100%;
background-color: rgb(255, 255, 255);
height: .50vh;
margin-bottom: 4%;
}

.linea-dos-clientes{
  width: 100%;
background-color: rgb(255, 255, 255);
height: .50vh;
 }

.bg{

  background-color: #061C5B;
}

/*Estilos del scroll */

/* Estilo para el scrollbar en navegadores webkit (Chrome, Safari) */
::-webkit-scrollbar {
  width: 15px; /* Ancho del scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color de fondo del scrollbar */
}

::-webkit-scrollbar-thumb {
  background: #007bff; /* Color del thumb (barra deslizante) */
  border-radius: 3px; /* Radio de borde del thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #0056b3; /* Cambio de color al pasar el cursor sobre el thumb */
}

.bgimg{

background: url('./img/fondoD.png');
background-repeat: no-repeat;
background-size: cover;

}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 1s ;
}


  .animacionc{animation: animacionc 3s linear;}


@keyframes animacion {
 
0%{transform: translateX(-100px);}
100%{transform: translateX(0px);}

}


@keyframes animacionc {
 
  0%{transform: translateX(200px);}
  100%{transform: translateX(0px);}
  
  }

  .navbar {
    transition: background-color 0.3s ease;
}

.button-hover-effect {
    transition: background-color 0.3s ease;
}

.button-hover-effect:hover {
    background-color: rgba(255, 255, 255, 0.3);
}



.carousel-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-slide {
  display: flex;
  flex-wrap: nowrap;
  width: 100%; /* Ancho completo del contenedor */
  height: 80vh; /* Altura predeterminada para otros navegadores */
}

.carousel-image {
  width: 100%; /* Ajusta el ancho del video al contenedor */
  height: auto; /* Ajusta automáticamente la altura */
}

/* Estilos específicos para Firefox */
@-moz-document url-prefix() {
  .carousel-slide {
    width: 300%; /* Ancho ampliado solo para Firefox */
    height: 80vh; /* Altura predeterminada para Firefox */
  }
}


.carousel-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  font-size: 20px;
  cursor: pointer;
}

.carousel-btn.prev {
  left: 0;
}

.carousel-btn.next {
  right: 0;
}


.animacion-aparecer{

animation: aparecer .5s linear;

}

@keyframes aparecer {
  0%{transform: scale(0); opacity: 0;}

100%{transform: scale(1);}

}

.imagen-consultoria {
  width: 100%;
  height: auto;
}

.image{

  background: url('./img/basada.avif');
  height: 50vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed; /* Añadido para fijar la imagen de fondo */

}


/* Agrega este código al archivo CSS */

/* Tarjetas */
.relative {
  perspective: 1000px;
}

.bg-white {
  backface-visibility: hidden;
}

.caso-wrapper {
  display: flex;
}

.caso {
  margin-bottom: 1.5rem;
}

.card {
  perspective: 1000px;
  height: 320px; /* Ajusta la altura según necesites */
  
}

.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s cubic-bezier(0.4, 0.0, 0.2, 1);
  transform-style: preserve-3d;
  
}

.card:hover .card-inner {
  transform: rotateY(180deg);
}

.front,
.back {
  
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: md; /* Ajusta el radio del borde si es necesario */
}

.front {
  background-color: #333;
  color: white;
  border-radius: 10px;

}

.back {
  background-color: #00336a;
  color: white;
  transform: rotateY(180deg);
  padding: 20px; /* Ajusta el padding según necesites */
  border-radius: 10px;

}

.front img {
  border-radius: 10px;
}



.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

/* custom.css */

/* Reglas de responsividad para dispositivos móviles */
/* custom.css */

/* Reglas de responsividad para dispositivos móviles y tabletas */
@media (max-width: 768px) {
  /* Contenedores */
  .custom-container {
    flex-direction: column;
    margin-right: auto;
    margin-left: auto;
    max-width: 90%; /* Limita el ancho de los contenedores */
  }

  
  /* Ajustar el tamaño de las imágenes */
   
  /* Ajustar tamaño de texto */
  .custom-text-xl {
    font-size: 1.25rem;
  }

  .custom-text-3xl {
    font-size: 1.875rem;
  }

  .custom-text-4xl {
    font-size: 2.25rem;
  }

  .custom-text-5xl {
    font-size: 3rem;
  }

  /* Centrar texto */
  .custom-text-center {
    text-align: center;
  }
}


.fondoFormulario{
  background: url('./img/fondo-formulario.avif');
  background-repeat: no-repeat;
  background-size: cover;
}
 .g-recaptcha{
  display: block !important;
  position: absolute;
  margin: -50px 0 0 0 !important;
  z-index: -999999;
  opacity: 0;
 }

/* Error 404 */

.sombra{

filter: drop-shadow(0px 5px 10px black);
}

.consultoria-card {
  transition: transform 0.5s ease-out;
}

.consultoria-card:hover {
  transform: scale(1.05);
}

/* Opcional: Añadir suavidad a la transición del color */
.consultoria-card .bg-blue-500:hover {
  transition: background-color 0.3s ease-in-out;
  background-color: #1e40af; /* Cambia el color de fondo en hover */
}
/* Estilo del botón */
button {
  font-size: 1rem; /* Tamaño del texto */
  line-height: 1.5;
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease; /* Transiciones suaves */
}

button:hover {
  background-color: #1e3a8a; /* Color más oscuro en hover */
  transform: scale(1.1); /* Aumenta ligeramente el tamaño en hover */
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2); /* Sombra más pronunciada en hover */
}

button:focus {
  outline: none; /* Elimina el borde del foco predeterminado */
  box-shadow: 0 0 0 4px rgba(59, 130, 246, 0.5); /* Añade un anillo de enfoque */
}

button:active {
  transform: scale(1.05); /* Reduce ligeramente el tamaño en active */
  background-color: #1c64f2; /* Cambia el color en active */
}

/* Opcional: estilo para dispositivos móviles */
@media (max-width: 768px) {
  button {
    width: 100%; /* Ancho completo en dispositivos móviles */
    font-size: 1.2rem; /* Tamaño de texto más grande en móviles */
  }
}
.carousel-container {
  position: relative;
  width: 100%;
  max-height: 600px; /* Establece una altura máxima para el contenedor */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
}

.carousel-slide {
  display: flex;
  transition: transform 0.8s ease-in-out;
  width: 300%;
  height: 100%; /* Asegura que la altura sea consistente */
}

.carousel-video-wrapper {
  flex: 0 0 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Ajusta la altura del contenedor */
  box-sizing: border-box;
  overflow: hidden;
}

.carousel-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
}

.carousel-video {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ajusta el video para cubrir el área del contenedor */
  margin: 0;
  padding: 0;
  border: none;
}

/* Ajustes adicionales para pantallas pequeñas */
@media (max-width: 768px) {
  .carousel-container {
    max-height: 300px; /* Ajusta la altura máxima en pantallas pequeñas */
  }
  .carousel-video {
    object-fit: contain; /* Ajusta el video para que se contenga dentro del área */
  }
}
html {
  scroll-behavior: smooth;
}
/* En tu archivo de CSS (App.css, por ejemplo) */
#ciberseguridad {
  scroll-margin-top: 100px; /* Ajusta este valor según el tamaño de tu barra de navegación */
}

.carousel-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  cursor: pointer;
  z-index: 10;
  padding: 10px;
  font-size: 24px;
}

.carousel-btn.prev {
  left: 10px;
}

.carousel-btn.next {
  right: 10px;
}

.carousel-btn:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
