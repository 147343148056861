/* stylevideo.css */

.container-todo {
    text-align: center;
    font-size: 2.5rem;
    font-weight: bold;
     
  }

  .container-todo h2{
    margin-top: 2%;
 
   }
  
  .container-video {
     height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
  }
  
  .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    z-index: 2;
    transition: background-color 0.3s ease, transform 0.3s ease; /* Agrega una transición suave */
  }
  
  .play-button:hover {
    background-color: rgba(0, 0, 0, 0.9);
    transform: translate(-50%, -50%) scale(1.05); /* Usa scale para un efecto sutil sin mover el botón */
  }
  
  
  .video-container {
    
    margin-top: 4%;
    height: 72vh;
    width: 50vw;
    box-shadow: 0px 0px 10px 1px black;
    border-radius: 10px;
    overflow: hidden;
    background-color: #000;
    position: relative;
  }
  
  .video-container video {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    outline: none;
    transition: transform 0.3s ease-in-out;
  }
  
  .video-container video:hover {
    transform: scale(1.02);
  }
  
  .video-container video.paused + .logo-overlay {
    display: block;
  }
  
  .logo-overlay {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    max-height: 30%;
    max-width: 30%;
    opacity: 0.8;
    transition: opacity 0.3s ease-in-out;
  }
 
  
  video::-webkit-media-controls-play-button,
  video::-webkit-media-controls-volume-slider,
  video::-webkit-media-controls-mute-button,
  video::-webkit-media-controls-fullscreen-button,
  video::-webkit-media-controls-current-time-display,
  video::-webkit-media-controls-time-remaining-display,
  video::-webkit-media-controls-timeline {
     color: white;
  }
  
  video::-webkit-media-controls-seek-back-button,
  video::-webkit-media-controls-seek-forward-button {
    color: white;
  }
  @media (max-width: 768px) {


    .video-container {
        margin-top: 2%;
        height: 72vh;
        width: 90%;
        box-shadow: 0px 0px 10px 1px black;
        border-radius: 10px;
        overflow: hidden;
        background-color: #000;
        position: relative;
      }
      
  }
/* stylevideo.css */

/* ... tu código CSS existente ... */

.video-container {
  position: relative;
}

.video-container video {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  outline: none;
}

/* Ocultar los controles de video predeterminados */
.video-container video::-webkit-media-controls-enclosure {
  display: none;
}

/* Personalizar la barra de progreso */
.video-container .custom-controls {
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  display: flex;
  align-items: center;
}

.video-container .progress-bar {
  width: 100%;
  height: 5px;
  background-color: #ddd;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
}

.video-container .progress-bar .progress {
  width: 0;
  height: 100%;
  background-color: #007bff;
  border-radius: 10px;
}

.video-container .progress-bar .thumb {
  width: 12px;
  height: 12px;
  background-color: #007bff;
  border-radius: 50%;
  position: absolute;
  top: -4px;
  transform: translateX(-50%);
}
.view-count { 
  position: absolute;
   top: 10px;
    right: 10px; 
    color: white;
     background-color: rgba(0, 0, 0, 0.7); 
     padding: 5px 10px;
      border-radius: 5px; 
      font-size: 1rem; }